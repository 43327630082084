'use client'

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { noop } from 'lodash'

import { ResponseError } from '@marketplace-web/shared/api-client'
import { getCountries, searchCities } from 'data/api'
import { transformCityDtos } from 'data/transformers/city'
import { transformCountryDtos } from 'data/transformers/country'
import { CityModel } from 'types/models/city'
import { CountryModel } from 'types/models/country'

type Location = {
  citySearch: (countryId, searchText?) => void
  fetchCountries: () => void
  getCountryById: (countryId) => CountryModel | undefined
  setCountry: (CountryModel) => void
  cities: Array<CityModel>
  countries: Array<CountryModel>
}

export type LocationContextType = {
  error: ResponseError | null
  fetchCountries: Dispatch<SetStateAction<Array<CityModel>>>
  getCountryById: () => CountryModel | null
  citySearch: Dispatch<SetStateAction<Array<CityModel>>>
  countries: Array<CountryModel> | []
  setCountry: Dispatch<SetStateAction<CountryModel>>
  country: CountryModel
  cities: Array<CityModel> | []
}

type LocationProviderProps = {
  children: ReactNode
}

export const LocationContext = createContext<Location>({
  citySearch: noop,
  fetchCountries: noop,
  getCountryById: () => undefined,
  cities: [],
  countries: [],
  setCountry: noop,
})

const LocationProvider = ({ children }: LocationProviderProps) => {
  const [countries, setCountries] = useState<Array<CountryModel>>(transformCountryDtos([]))
  const [country, setCountry] = useState<CountryModel>()
  const [cities, setCities] = useState<Array<CityModel>>(transformCityDtos([]))
  const [error, setError] = useState<string | undefined>()

  const fetchCountries = useCallback(async () => {
    const response = await getCountries()

    if ('errors' in response) {
      setError(response.errors[0]?.value)

      return
    }

    const transformedCountries = transformCountryDtos(response.countries)

    setCountries(transformedCountries)
  }, [])

  const getCountryById = useCallback(
    (id: number | null): CountryModel | undefined =>
      countries.find(countryElement => countryElement.id === id),
    [countries],
  )

  const citySearch = useCallback(async (countryId, searchText = '') => {
    const response = await searchCities({ countryId, searchText })

    if ('errors' in response) {
      setError(response.errors[0]?.value)

      return
    }

    const transformedCities = transformCityDtos(response.cities)

    if (transformedCities) {
      setCities(transformedCities)
    }
  }, [])

  const value = useMemo(
    () => ({
      error,
      fetchCountries,
      getCountryById,
      citySearch,
      countries,
      setCountry,
      country,
      cities,
    }),
    [fetchCountries, citySearch, countries, cities, error, setCountry, country, getCountryById],
  )

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}

export default LocationProvider
