import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useSession } from '@marketplace-web/shared/session'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'
import { requestedAdEvent } from '_libs/common/event-tracker/events'

function useRequestedAdLogging(placementId: string) {
  const { screen } = useSession()
  const { userCountry: countryCode } = useSystemConfiguration() || {}

  const isRequestedAdLoggingEnabled = useFeatureSwitch('web_ads_requested_ad_logging')

  const { track } = useTracking()

  const onRequest = () => {
    if (!isRequestedAdLoggingEnabled || !countryCode) return

    track(
      requestedAdEvent({
        placementId,
        screen,
        countryCode,
      }),
    )
  }

  return { onRequest }
}

export default useRequestedAdLogging
