'use client'

import { ReactNode, useCallback } from 'react'

import { crashAdComponentEvent } from '_libs/common/event-tracker/events'
import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { AdPage } from '../../../constants'

type Props = {
  pageName: AdPage
  placementId: string
  children: ReactNode
}

const AdErrorBoundary = ({ children, pageName, placementId }: Props) => {
  const { track } = useTracking()

  const handleErrorBoundary = useCallback(
    (error: Error | null) => {
      track(
        crashAdComponentEvent({
          error: error ? error.toString() : '',
          placementId,
          pageName,
        }),
      )
    },
    [placementId, pageName, track],
  )

  return <ErrorBoundary onError={handleErrorBoundary}>{children}</ErrorBoundary>
}

export default AdErrorBoundary
