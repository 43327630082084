'use client'

import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Cell, Label, Radio } from '@vinted/web-ui'
import { isNumber, throttle } from 'lodash'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { InputDropdown, InputDropdownRenderProps, List } from '@marketplace-web/shared/ui-helpers'
import { CityModel } from 'types/models/city'
import { useProfileSettingsContext } from '@marketplace-web/domain/profile-settings'

import useLocationContext from './useLocationContext'

const REQUEST_THROTTLE = 200

const CitySelect = () => {
  const translate = useTranslate('profile_settings.location.city')
  const breakpoints = useBreakpoint()
  const {
    setSettings,
    settings: { cityId, cityTitle, countryId },
  } = useProfileSettingsContext()

  const { cities, citySearch } = useLocationContext()

  const [selectedId, setSelectedId] = useState(cityId)
  const [isPopularCities, setIsPopularCities] = useState(true)

  const isCountrySelected = isNumber(countryId)

  useEffect(() => {
    setSelectedId(cityId)
  }, [cityId])

  const selectCity = (id: number) => {
    const selectedCity = cities.find(city => city.id === id)

    if (!selectedCity) return

    setSettings(prevSettings => ({
      ...prevSettings,
      ...{ cityId: selectedCity.id, cityTitle: selectedCity.title },
    }))
  }

  const throttledSearchCitiesRequest = useCallback(
    (searchText?: string) => {
      if (!countryId) return
      throttle(() => citySearch(countryId, searchText), REQUEST_THROTTLE)()
    },
    [countryId, citySearch],
  )

  useEffect(() => {
    if (!cityTitle) {
      throttledSearchCitiesRequest()
    }
  }, [cityTitle, throttledSearchCitiesRequest])

  const handleCitySelect = (city: CityModel, inputRenderProps: InputDropdownRenderProps) => () => {
    inputRenderProps.setIsSaveEnabled(true)

    if (breakpoints.phones) {
      setSelectedId(city.id)

      return
    }

    inputRenderProps.closeDropdown()

    selectCity(city.id)
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!isCountrySelected) return

    const { value } = event.currentTarget

    setIsPopularCities(!value)
    throttledSearchCitiesRequest(value)
  }

  const resetCityData = () => {
    setIsPopularCities(true)
    setSelectedId(cityId)
    throttledSearchCitiesRequest()
  }

  function handleSave() {
    if (!selectedId) return

    selectCity(selectedId)
    resetCityData()
  }

  function renderCityList(inputRenderProps: InputDropdownRenderProps) {
    return (
      <>
        {isPopularCities && (
          <Label type={Label.Type.Leading} text={translate('popular_cities_label')} />
        )}
        <List>
          {cities?.map(city => (
            <Cell
              id={city.id.toString()}
              onClick={handleCitySelect(city, inputRenderProps)}
              key={city.id}
              title={city.title}
              suffix={
                <Radio
                  tabIndex={-1}
                  name={`${city.id}-radio`}
                  checked={city.id === selectedId}
                  value={city.id}
                  onChange={() => undefined}
                  aria={{
                    'aria-labelledby': city.id.toString(),
                  }}
                />
              }
              clickable
            />
          ))}
        </List>
      </>
    )
  }

  return (
    <InputDropdown
      name="city-select"
      title={translate('title')}
      placeholder={translate(isCountrySelected ? 'placeholder' : 'no_country_placeholder')}
      value={cityTitle}
      render={renderCityList}
      disabled={!isCountrySelected}
      onChange={handleInputChange}
      onCancel={resetCityData}
      onClose={resetCityData}
      onSave={handleSave}
    />
  )
}
export default CitySelect
