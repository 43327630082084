export async function getVanCreative(
  url: string,
  isVanConsentCategoryEnabled: boolean,
  personalize: boolean,
) {
  const query = `?personalize=${personalize}`
  const fullUrl = `${url}${query}`

  const response = await fetch(isVanConsentCategoryEnabled ? fullUrl : url)
  const text = await response.text()

  return { response, text }
}
