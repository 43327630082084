import { PrebidConsentManagementCmpApiType } from '../../../../constants'

export function initPrebidSetup(adagioParams: object | undefined) {
  window.pbjs = window.pbjs || {}
  window.pbjs.que = window.pbjs.que || []

  window.pbjs.que.push(() => {
    window.pbjs.bidderSettings = {
      standard: {
        storageAllowed: true,
      },
    }

    window.pbjs.setConfig({
      accessDevice: true,
      transmitTid: true,
      enableTIDs: true,
      realTimeData: adagioParams
        ? {
            dataProviders: [{ name: 'adagio', params: adagioParams }],
          }
        : undefined,
      useBidCache: true,
      priceGranularity: {
        buckets: [
          {
            precision: 2,
            min: 0,
            max: 20,
            increment: 0.01,
          },
          {
            precision: 2,
            min: 21,
            max: 99,
            increment: 1,
          },
        ],
      },
      enableSendAllBids: false,
      currency: {
        adServerCurrency: 'EUR',
        granularityMultiplier: 1,
      },
      timeoutBuffer: 200,
      userSync: {
        filterSettings: {
          iframe: {
            bidders: '*',
            filter: 'include',
          },
        },
        userIds: [
          {
            name: 'id5Id',
            params: {
              partner: 700,
            },
            storage: {
              type: 'html5',
              name: 'id5id',
              expires: 90,
              refreshInSeconds: 8 * 3600,
            },
          },
          {
            name: 'criteo',
          },
        ],
        auctionDelay: 50,
      },
    })
  })
}

export function setPrebidConsentManagement(isCookieConsentVersion: boolean) {
  if (!isCookieConsentVersion) return

  window.pbjs.que.push(() => {
    window.pbjs.mergeConfig({
      consentManagement: {
        gdpr: {
          cmpApi: PrebidConsentManagementCmpApiType.IAB,
          defaultGdprScope: true,
        },
        usp: {
          cmpApi: PrebidConsentManagementCmpApiType.IAB,
        },
      },
    })
  })
}
