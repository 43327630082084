'use client'

import { useContext } from 'react'

import ProfileSettingsContext from '../containers/ProfileSettingsProvider/ProfileSettingsContext'

function useProfileSettingsContext() {
  const context = useContext(ProfileSettingsContext)

  if (!context) {
    throw new Error('useProfileSettingsContext must be used within a ProfileSettingsProvider')
  }

  return context
}

export default useProfileSettingsContext
