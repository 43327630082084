'use client'

import { Cell, Toggle, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useProfileSettingsContext } from '@marketplace-web/domain/profile-settings'

const CityVisibilityToggle = () => {
  const translate = useTranslate('profile_settings.location.city_visibility')
  const {
    setSettings,
    settings: { isCityVisible },
  } = useProfileSettingsContext()
  const toggleTitleId = 'toggle-title-city-visibility'

  function handleClick() {
    setSettings(prevSettings => ({
      ...prevSettings,
      isCityVisible: !isCityVisible,
    }))
  }

  return (
    <Cell
      styling={Cell.Styling.Wide}
      title={<Text type={Text.Type.Title} text={translate('title')} as="h3" id={toggleTitleId} />}
      suffix={
        <Toggle
          name="city-visibility-toggle"
          aria={{ 'aria-labelledby': toggleTitleId }}
          onClick={handleClick}
          checked={isCityVisible}
          onChange={() => undefined}
        />
      }
    />
  )
}

export default CityVisibilityToggle
