'use client'

import Script from 'next/script'

import { useLocation } from '@marketplace-web/shared/browser'

import { getPubstackDomainKey } from './utils'

const Pubstack = () => {
  const pubstackDomainKey = getPubstackDomainKey(useLocation().host)

  if (!pubstackDomainKey) return null

  return (
    <Script
      id="pubstack-script"
      data-testid="pubstack-script"
      src={`https://boot.pbstck.com/v1/tag/${pubstackDomainKey}`}
      strategy="lazyOnload"
    />
  )
}

export default Pubstack
