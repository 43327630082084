import { isInternalHostname, normalizeHost } from '@marketplace-web/shared/utils'

import { MapHostnameToPubstackKey } from '../../../../constants'

export function getPubstackDomainKey(hostname: string) {
  const normalizedHostname = normalizeHost(hostname)

  if (!isInternalHostname(normalizedHostname)) return null

  return MapHostnameToPubstackKey[normalizedHostname]
}
