'use client'

import { Label, Divider, Cell, Card } from '@vinted/web-ui'

import { FormattedMessage } from '@marketplace-web/shared/i18n'
import { ConditionalWrapper, SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

import CountrySelect from './CountrySelect'
import CitySelect from './CitySelect'
import CityVisibilityToggle from './CityVisibilityToggle'

const Location = () => {
  const settingsBackgroundUpdateAbTest = useAbTest('settings_background_update_web')
  const isSettingsBackgroundUpdateAbTestEnabled = settingsBackgroundUpdateAbTest?.variant === 'on'

  const renderWrapper = (children: JSX.Element) => <Card overflow="visible">{children}</Card>

  return (
    <>
      <Label text={<FormattedMessage id="profile_settings.location.title" />} />
      <ConditionalWrapper
        wrapper={renderWrapper}
        condition={isSettingsBackgroundUpdateAbTestEnabled}
      >
        <Cell styling={Cell.Styling.Tight}>
          <SeparatedList separator={<Divider />}>
            <CountrySelect />
            <CitySelect />
            <CityVisibilityToggle />
          </SeparatedList>
        </Cell>
      </ConditionalWrapper>
    </>
  )
}

export default Location
