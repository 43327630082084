import { useContext, useSyncExternalStore } from 'react'

import { BrazeContext } from '_libs/common/braze/containers/BrazeProvider'
import {
  transformBrazeFullScreenInAppMessageDto,
  transformBrazeModalInAppMessageDto,
  transformBrazeSlideUpInAppMessageDto,
} from '_libs/common/braze/transformers'
import { Store } from '_libs/utils/generic-store'

// TODO: move me to a more suitable location
function useStore<T>(store: Store<T | null>): T
function useStore<T, U>(store: Store<T | null>, transform: (entity: T) => U): U
function useStore<T, U>(store: Store<T | null>, transform?: (entity: T) => U) {
  const entity = useSyncExternalStore(
    store.subscribe,
    () => store.state,
    () => null,
  )

  if (transform && entity) return transform(entity)

  return entity
}

function useBrazeInAppMessage() {
  const context = useContext(BrazeContext)

  const modalInAppMessage = useStore(
    context.modalInAppMessageStore,
    transformBrazeModalInAppMessageDto,
  )

  const fullScreenInAppMessage = useStore(
    context.fullScreenInAppMessageStore,
    transformBrazeFullScreenInAppMessageDto,
  )

  const slideUpInAppMessage = useStore(
    context.slideUpInAppMessageStore,
    transformBrazeSlideUpInAppMessageDto,
  )

  return {
    modalInAppMessage: modalInAppMessage ?? fullScreenInAppMessage,
    notificationInAppMessage: slideUpInAppMessage,
  }
}

export default useBrazeInAppMessage
