'use client'

import { Image, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { Suspense, useMemo } from 'react'

import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { CDN_IMAGES_URL } from '@marketplace-web/shared/ui-helpers'

import { AdPlatform, AdShape } from '../../../constants'

type Props = {
  shape: AdShape
  platform: AdPlatform
}

type PlaceholderVariation = {
  shape: AdShape
  placeholders: Record<'desktop' | 'mobile', Array<string>>
}

const placeholderVariations: Array<PlaceholderVariation> = [
  {
    shape: AdShape.Leaderboard,
    placeholders: {
      desktop: [
        '/ads/placeholders/leaderboard/desktop-1',
        '/ads/placeholders/leaderboard/desktop-2',
      ],
      mobile: ['/ads/placeholders/leaderboard/mobile-1', '/ads/placeholders/leaderboard/mobile-2'],
    },
  },
]

const getAdPlaceholderImageSrc = (shape: AdShape, isDarkMode: boolean, isMobile: boolean) => {
  const placeholders = placeholderVariations.find(
    ({ shape: variationShape }) => variationShape === shape,
  )?.placeholders

  if (!placeholders) return null

  const darkModeSuffix = isDarkMode ? '-dark' : ''
  const platformPlaceholders = isMobile ? placeholders.mobile : placeholders.desktop
  const indexBasedOnHour = new Date().getUTCHours() % platformPlaceholders.length

  return `${CDN_IMAGES_URL}${platformPlaceholders[indexBasedOnHour]!}${darkModeSuffix}.png`
}

const AdPlaceholder = ({ shape, platform }: Props) => {
  const translate = useTranslate('advertisements')
  const isDarkMode = useIsDarkMode()
  const isMobile = platform === AdPlatform.Mobile

  const adPlaceholderImageSrc = useMemo(
    () => getAdPlaceholderImageSrc(shape, isDarkMode, isMobile),
    [shape, isDarkMode, isMobile],
  )

  if (!adPlaceholderImageSrc) return null

  return (
    <Suspense>
      <div
        className={classNames('ad-placeholder', `ad-placeholder--${shape}`)}
        data-testid="ad-placeholder"
        aria-hidden="true"
        suppressHydrationWarning
      >
        <Image testId="ad-placeholder-image" src={adPlaceholderImageSrc} />
        {!isMobile && (
          <div className="ad-placeholder__text" suppressHydrationWarning>
            <Text
              as="h3"
              type={Text.Type.Subtitle}
              theme="primary"
              bold
              text={translate('placeholder')}
            />
          </div>
        )}
      </div>
    </Suspense>
  )
}

export default AdPlaceholder
