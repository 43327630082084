'use client'

import { useEffect } from 'react'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { SelectableInputDropdown } from '@marketplace-web/shared/ui-helpers'
import { CountryModel } from 'types/models/country'
import { useProfileSettingsContext } from '@marketplace-web/domain/profile-settings'

import useLocationContext from './useLocationContext'

const CountrySelect = () => {
  const translate = useTranslate('profile_settings.location.country')
  const {
    setSettings,
    settings: { countryId },
  } = useProfileSettingsContext()

  const { fetchCountries, countries, getCountryById, setCountry, citySearch } = useLocationContext()
  const country = getCountryById(countryId)

  const selectCountry = (selectedCountryId: number) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      ...{ countryId: selectedCountryId, cityId: null, cityTitle: null },
    }))

    citySearch(selectedCountryId)
  }

  useEffect(() => {
    fetchCountries()
  }, [fetchCountries])

  function handleSave(selectedCountry?: CountryModel | null) {
    if (!selectedCountry) return

    if (country?.id === selectedCountry.id) return

    selectCountry(selectedCountry.id)
    setCountry(selectedCountry)
  }

  return (
    <SelectableInputDropdown
      name="country-select"
      inputTitle={translate('title')}
      placeholder={translate('placeholder')}
      data={countries}
      onSave={handleSave}
      selectedValue={country}
    />
  )
}

export default CountrySelect
